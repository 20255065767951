import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatIconModule, MatInputModule, MatPaginatorModule, MatSortModule, MatTableModule, MatDatepickerModule, MatFormFieldModule,MatCheckboxModule, MatDialogModule, MatSnackBarModule, MatExpansionModule, MatMenuModule, MatNativeDateModule } from '@angular/material';
import { MatSidenavModule } from "@angular/material/sidenav";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatExpansionModule,
    MatMenuModule,
  ],
  exports: [
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatSortModule,
    MatInputModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatExpansionModule,
    MatMenuModule,
  ]
})
export class MaterialModule { }
