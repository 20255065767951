import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, TemplateRef } from '@angular/core';
import { MessagingBusService, ShowLoader, HideLoader, CommandType, ShowNetworkError } from './messaging.bus.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
// import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    http: HttpClient;
    defaultHeaders: any

    constructor(public _http: HttpClient, public serviceBus: MessagingBusService, public router: Router, private _snackBar: MatSnackBar) {
        this.http = _http;
    }

    protected get headers(): HttpHeaders {
        this.defaultHeaders = new HttpHeaders()
        // this.defaultHeaders = this.defaultHeaders.append('Content-Type',
        //     'application/x-www-form-urlencoded')
        this.defaultHeaders = this.defaultHeaders.append('ApiKey', environment.api_key)
        this.defaultHeaders = this.defaultHeaders.append('rejectUnauthorized', 'false')
        if (sessionStorage.getItem('session'))
            this.defaultHeaders = this.defaultHeaders.append('SessionToken', JSON.parse(sessionStorage.getItem('session')))
        return this.defaultHeaders;
    }

    protected get(relativeUrl: string, isAsyc: boolean): Observable<any> {
        if (!isAsyc) {
            this.serviceBus.notify(ShowLoader);
        }
        return this.http.get(environment.url + relativeUrl, { headers: this.headers }).pipe(map(
            apiResponse => {
                let response: any;
                response = apiResponse;
                if (!isAsyc) {
                    this.serviceBus.notify(HideLoader);
                }

                if (!response.status) {
                    this.serviceBus.notify({ "CommandType": CommandType.ShowAlert, "MessageText": this.getErrorMessageText(response.errors), "Error": true });
                }
                return response;
            }))
            .pipe(catchError(error => {
                if (!isAsyc) {
                    this.serviceBus.notify(HideLoader);
                }
                console.log(error)
                if (error.status == '401') {
                }
                if (!error.status) {
                    this.serviceBus.notify(ShowNetworkError);
                }
                throw error;
            }));
    }

    protected asyncPost(relativeUrl: string, data: any): Observable<any> {
        return this.post(relativeUrl, data, true);
    }

    protected syncPost(relativeUrl: string, data: any): Observable<any> {
        return this.post(relativeUrl, data, false);
    }

    protected syncGet(relativeUrl: string) {
        return this.get(relativeUrl, false)
    }

    protected syncPut(relativeUrl: string, data: any): Observable<any> {
        return this.put(relativeUrl, false, data)
    }

    protected post(relativeUrl: string, data: any, isAsyc: boolean): Observable<any> {
        if (!isAsyc) {
            this.serviceBus.notify(ShowLoader);
        }
        return this.http.post<any>(environment.url + relativeUrl, data, { headers: this.headers }).pipe(map(
            response => {
                console.log(response)
                if (!isAsyc) {
                    this.serviceBus.notify(HideLoader);
                }

                if (relativeUrl == 'connect/token') {
                    return response;
                }

                if (!response.status) {
                    this.serviceBus.notify({ "CommandType": CommandType.ShowAlert, "MessageText": this.getErrorMessageText(response.errors), "Error": response.hasErrors });
                }
                else {
                    if (response.data != null) {
                        if (response.data.isLoggedIn != true) {
                            this.serviceBus.notify({ "CommandType": CommandType.ShowAlert, "MessageText": response.data.message, "Error": response.hasErrors });
                        }
                    }

                }

                return response;
            })
        ).pipe(catchError(error => {
            if (!isAsyc) {
                this.serviceBus.notify(HideLoader);
            }
            console.log(error)
            if (error.status == '401') {
            } else {
                this.serviceBus.notify(HideLoader);
                this.serviceBus.notify(ShowNetworkError);
            }
            if (!error.status) {
                this.serviceBus.notify(ShowNetworkError);
            }
            throw error;
        }));
    }

    protected put(relativeUrl: string, isAsyc: boolean, data: any): Observable<any> {
        if (!isAsyc) {
            this.serviceBus.notify(ShowLoader);
        }
        return this.http.put<any>(environment.url + relativeUrl, data, { headers: this.headers }).pipe(map(
            response => {
                console.log(response)
                if (!isAsyc) {
                    this.serviceBus.notify(HideLoader);
                }

                if (!response.status) {
                    this.serviceBus.notify({ "CommandType": CommandType.ShowAlert, "MessageText": 'Some error..', "Error": response.hasErrors });
                }
                else {
                    if (response.data != null) {
                        this.serviceBus.notify({ "CommandType": CommandType.ShowAlert, "MessageText": '', "Error": response.hasErrors });
                    }

                }

                return response;
            })
        ).pipe(catchError(error => {
            if (!isAsyc) {
                this.serviceBus.notify(HideLoader);
            }
            console.log(error)
            if (error.status == '401') {
                // this.showToast('Session Expired', 'OK');
            } else {
                this.serviceBus.notify(HideLoader);
                this.serviceBus.notify(ShowNetworkError);
            }
            if (!error.status) {
                this.serviceBus.notify(ShowNetworkError);
            }
            throw error;
        }));
    }

    private getErrorMessageText(errors: any): string {
        if (errors.length != null && errors.length > 0) {
            return errors.join("<BR/>");
        } else {
            return errors;
        }
    }
    public showToast(msg: string, actionTxt: string, toastConfig?: MatSnackBarConfig<any> | undefined) {
        let commonToastConfig: MatSnackBarConfig<any> | undefined = {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 5000,
        }
        this._snackBar.open(msg, actionTxt, toastConfig ? toastConfig : commonToastConfig)
    }
    // showDialog(template: ComponentType<any> | TemplateRef<any>, config?: MatDialogConfig<any> | undefined) {
    //     this.dialog.open(template, config)
    // }
}