import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {

  public getPractices(paginate: any,currentPage: any){
    return this.syncGet('practice/assigned?paginate='+paginate+'&currentPage='+currentPage)
  }
}
