import { ChangeDetectorRef, Component } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { CommandType, MessagingBusService } from './base/messaging.bus.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HmsReports';

  isLoading: boolean = false;
  alertActive: boolean = false;
  msg: string = '';
  case: boolean = false;

  constructor(
    private messageBus: MessagingBusService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  showLoader() {
    console.log('ShowLoader');
    this.isLoading = true;
  }

  hideLoader() {
    console.log('HideLoader');
    this.isLoading = false;
  }

  openAlert(alertMsg: string, error: boolean) {
    this.msg = alertMsg;
    this.alertActive = true;
    this.case = error;
  }

  closeAlert(event: boolean) {
    this.alertActive = event;
  }

  // Handle Session Timeout
  // { CommandType: CommandType; MessageText: string; }
  handleCommand(message: any) {
    // console.log(message);
    switch (message.CommandType) {
      case CommandType.ShowAlert:
        this.openAlert(message.MessageText, message.Error);
        break;
      case CommandType.ShowLoader:
        this.showLoader();
        break;
      case CommandType.HideLoader:
        this.hideLoader();
        break;
      case CommandType.HideAlert:
        this.closeAlert(false);
        break;
      default:
        console.log('Unable to process');
    }
  }

  ngOnInit() {
    this.messageBus.Message.subscribe((message) => {
      this.handleCommand(message);
	  this.changeDetectorRef.detectChanges();
    });
  }
}
