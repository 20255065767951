import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MasterReport } from 'src/app/_interface/masterreport.model';
import { MatSort } from '@angular/material/sort'
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { MasterListService } from './masterlist.service';
import { MatDatepicker } from '@angular/material/datepicker';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BaseService } from 'src/app/base/base.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClaimOverviewComponent } from '../claim-overview/claim-overview.component';
import { MatCheckbox } from '@angular/material';
import { Subscription } from 'rxjs';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MMMM, YYYY',
  },
  display: {
    dateInput: 'MMMM, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-masterlist',
  templateUrl: './masterlist.component.html',
  styleUrls: ['./masterlist.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class MasterlistComponent implements OnInit, AfterViewInit, OnDestroy {

  public displayedColumns = ['PatientId', 'FirstName', 'LastName', 'dateOfBirth', 'diagnosis', 'initialUploadDate','trainingCompletedate', '96453', 'count_99454', '99454', 'total_Service_time_99457', '99457', '99458', '99458x2', 'claimDetails', 'action'];
  public dataSource = new MatTableDataSource<MasterReport>();
  filterForm: FormGroup
  newClaim: boolean = true
  claimForm: FormGroup = new FormGroup({
    PatientId: new FormControl(''),
    Insurance: new FormControl(''),
    InsuranceDate: new FormControl(''),
    ClaimId: new FormControl(null),
    ClaimedDate: new FormControl(null),
    IsClaimed: new FormControl(null)
  })
  maxDate = new Date()
  selection = new SelectionModel<MasterReport>(true, []);
  currentDate = new Date();
  close!: MatDialogRef<unknown, any>;
  lastUploadedDate: string;
  public get period(): AbstractControl { return this.filterForm.get('from') }
  public get claimDate(): AbstractControl { return this.claimForm.get('ClaimedDate') }
  formSubmitSubscription: Subscription
  reports = ['99453', '99454', '99457', '99458#1', '99458#2']

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('optional', { static: false }) template: any;

  constructor(private masterListService: MasterListService, private fb: FormBuilder, private baseService: BaseService, public router: Router, private dialog: MatDialog) { }

  ngOnInit() {
    console.log(this.router.url)
    if (this.router.url === '/reports/masterreport-claim') {
      this.displayedColumns = ['select', 'PatientId', 'Name', 'dateOfBirth', 'diagnosis', 'InitialUploadDate','Kickoffdate', 'count_99454', 'total_Service_time_99457', 'BillingCycleStartDate', 'action','claimDetails']
    }
    console.log('ngOnInit ******')
    this.filterForm = this.fb.group({
      'from': [moment(), Validators.required],
      // 'to': [new Date(), Validators.required]
    })
    this.getMasterList()
    this.getLastUploadedDate()
    this.formSubmitSubscription = this.masterListService.formSubmitted$.subscribe(res => {
      console.log(res)
      this.onSubmit()
    })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
  }

  ngOnDestroy() {
    this.formSubmitSubscription.unsubscribe()
  }

  getLastUploadedDate() {
    this.masterListService.getLastUploadedDate()
      .subscribe(res => {
        console.log(res)
        if (res['status']) {
          this.lastUploadedDate = res['data'][0].LastUploadedDate
        } else {
          this.baseService.showToast(res.errors[0], 'Close');
        }
      }, (error: any) => {
        console.log(error);
        if (error.error.errors) {
          this.baseService.showToast(error.error.errors[0], 'Close');
        } else {
          this.baseService.showToast(error.statusText, 'Close');
        }
      })
  }

  getMasterList() {
    console.log(moment(this.period.value).startOf('month').format('YYYY-MM-DD'), moment(this.period.value).endOf('month').format('YYYY-MM-DD'))
    this.masterListService.getMedicareReport(moment(this.period.value).startOf('month').format('YYYY-MM-DD'), moment(this.period.value).endOf('month').format('YYYY-MM-DD'))
      .subscribe(res => {
        console.log(res)
        if (res['status']) {
          this.dataSource.data = res['data'] as MasterReport[]
        } else {
          this.baseService.showToast(res.errors[0], 'Close');
        }
      }, (error: any) => {
        console.log(error);
        if (error.error.errors) {
          this.baseService.showToast(error.error.errors[0], 'Close');
        } else {
          this.baseService.showToast(error.statusText, 'Close');
        }
      })
  }

  openDialog(insurance?: string, patientDetail?: MasterReport, checkBox?: MatCheckbox) {
    this.claimForm.reset({
      "PatientId": "",
      "Insurance": "",
      "InsuranceDate": "",
      "ClaimId": null,
      "ClaimedDate": null,
      "IsClaimed": null
    })
    if (checkBox) {
      checkBox.checked = null
      this.claimForm.patchValue({
        "PatientId": patientDetail['PatientId'],
        "Insurance": insurance,
        "InsuranceDate": moment(patientDetail[`date_${insurance}`.replace('#', '_')]).format('YYYY-MM-DD')
      })
    }
    let dialogRef = this.dialog.open(ClaimOverviewComponent, { disableClose: true, data: { form: this.claimForm, newClaim: this.newClaim } })

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      this.getMasterList()
    });
  }

  viewClaim(insurance: string, patientDetail: MasterReport, checkBox: MatCheckbox) {
    console.log({ ...patientDetail, Insurance: insurance })
    this.masterListService.getClaimData({ ...patientDetail, Insurance: insurance }).subscribe(res => {
      console.log(res)
      if (res['status']) {
        this.newClaim = false
        this.openDialog(insurance, patientDetail, checkBox)
        checkBox.checked = true
        this.claimForm.patchValue({
          "ClaimId": res['data'][0][`ClaimId_${insurance.replace('#', '_')}`],
          "ClaimedDate": res['data'][0][`ClaimedDate_${insurance.replace('#', '_')}`],
          "IsClaimed": res['data'][0][`IsClaimed_${insurance.replace('#', '_')}`]
        })
      } else {
        this.baseService.showToast(res.errors[0], 'Close');
      }
    }, (error: any) => {
      console.log(error);
      if (error.error.errors) {
        this.baseService.showToast(error.error.errors[0], 'Close');
      } else {
        this.baseService.showToast(error.statusText, 'Close');
      }
    })
    checkBox.checked = true
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.period.value;
    ctrlValue.year(normalizedMonth.year())
    ctrlValue.month(normalizedMonth.month());
    this.period.setValue(ctrlValue);
    datepicker.close();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.fetchEligiblePatients().length;
    return numSelected === numRows;
  }

  fetchEligiblePatients() {
    return this.dataSource.data.filter(element => element['date_99453'] && !element['IsClaimed_99453'] || element['date_99454'] && !element['IsClaimed_99454'] || element['date_99457'] && !element['IsClaimed_99457'] || element['date_99458_1'] && !element['IsClaimed_99458_1'] || element['date_99458_2'] && !element['IsClaimed_99458_2'])
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.fetchEligiblePatients());
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MasterReport): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row of id ${row['PatientId']}`;
  }

  onSubmit() {
    if (this.claimDate.value) this.claimDate.setValue(moment(this.claimDate.value).format('YYYY-MM-DD'))
    this.masterListService.putClaimUpdate(this.initiateClaim()).subscribe(res => {
      console.log(res)
      if (res['status']) {
        this.updateReports()
      } else {
        this.baseService.showToast(res.errors[0], 'Close');
      }
    }, (error: any) => {
      console.log(error);
      if (error.error.errors) {
        this.baseService.showToast(error.error.errors[0], 'Close');
      } else {
        this.baseService.showToast(error.statusText, 'Close');
      }
    })
  }

  updateReports() {
    if (this.selection.selected.length) {
      console.log(this.selection.selected)
      this.selection.selected.forEach(patient => {
        this.reports.forEach(report => {
          if (patient[`date_${report}`.replace('#', '_')] && !patient[`IsClaimed_${report}`.replace('#', '_')])
            patient[`IsClaimed_${report}`.replace('#', '_')] = this.claimForm.get('IsClaimed').value
        })
      })
    } else {
      this.dataSource.data[this.dataSource.data.findIndex(patient => patient['PatientId'] === this.claimForm.get('PatientId').value)][`IsClaimed_${this.claimForm.get("Insurance").value}`.replace('#', '_')] = this.claimForm.get('IsClaimed').value
    }
    this.selection.clear()
  }

  initiateClaim() {
    const insurancesToClaim = { "InsuranceInfo": [] }
    if (this.selection.selected.length) {
      console.log(this.selection.selected)
      this.selection.selected.forEach(patient => {
        this.reports.forEach(report => {
          if (patient[`date_${report}`.replace('#', '_')] && !patient[`IsClaimed_${report}`.replace('#', '_')])
            insurancesToClaim.InsuranceInfo.push({
              "PatientId": patient['PatientId'],
              "Insurance": report,
              "InsuranceDate": moment(patient[`date_${report}`.replace('#', '_')]).format("YYYY-MM-DD"),
              "ClaimId": this.claimForm.get('ClaimId').value,
              "ClaimedDate": this.claimForm.get('ClaimedDate').value ? moment(this.claimForm.get('ClaimedDate').value).format('YYYY-MM-DD') : this.claimForm.get('ClaimedDate').value,
              "IsClaimed": this.claimForm.get('IsClaimed').value
            })
        })
      })
    }
    else {
      insurancesToClaim.InsuranceInfo.push(this.claimForm.value)
    }
    console.log(insurancesToClaim)
    return insurancesToClaim
  }

  showClaimStatus(record: MasterReport) {
    let disable: boolean = true
    this.reports.forEach(report => {
      if (record[`date_${report}`.replace('#', '_')] && !record[`IsClaimed_${report}`.replace('#', '_')])
        disable = false
    })
    return disable
  }

}
