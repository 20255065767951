import { Injectable } from "@angular/core";
import { BaseService } from "src/app/base/base.service";

@Injectable({
  providedIn: 'root'
})

export class ConsentReportService extends BaseService{
  public getConsentReport(eligible: string,currentPage: any,pageSize: any, searchElement: string,startDate: any){
    return this.syncGet('patient/insurer/99453/'+eligible+'?paginate='+pageSize+'&currentPage='+currentPage+'&searchElement='+searchElement+'&practiceId='+JSON.parse(sessionStorage.getItem('practice')).Id+'&startDate='+startDate)
  }

  // public getConsentReport(currentPage: any,pageSize: any, searchElement: string){
  //   // {{url}}/patient/insurer/99453/eligible?paginate=50&currentPage=1&searchElement=&practiceId=1&startDate=2021-12-01
  //   return this.syncGet('patient/insurer/99453/eligible?paginate='+pageSize+'&currentPage='+currentPage+'&searchElement='+searchElement+'&practiceId='+JSON.parse(sessionStorage.getItem('practice')).Id)
  // }

  public updateAgreementOrTraining(url: string,data: any){
    return this.syncPut(url,data)
  }

  // public getReport96454(currentPage: any,pageSize: any, searchElement: string){
  //   return this.syncGet('patient/insurer/99454?paginate='+pageSize+'&currentPage='+currentPage+'&searchElement='+searchElement+'&practiceId='+JSON.parse(sessionStorage.getItem('practice')).Id)
  // }

  public getReport96454(eligible: string,currentPage: any,pageSize: any, searchElement: string,startDate: any){
    return this.syncGet('patient/insurer/99454/'+eligible+'?paginate='+pageSize+'&currentPage='+currentPage+'&searchElement='+searchElement+'&practiceId='+JSON.parse(sessionStorage.getItem('practice')).Id+'&startDate='+startDate)
  }

  public getReport96457(eligible: string,currentPage: any,pageSize: any, searchElement: string,startDate: any){
    // return this.syncGet('patient/insurer/99457?paginate='+pageSize+'&currentPage='+currentPage+'&searchElement='+searchElement+'&practiceId='+JSON.parse(sessionStorage.getItem('practice')).Id)
    return this.syncGet('patient/insurer/99457/'+eligible+'?paginate='+pageSize+'&currentPage='+currentPage+'&searchElement='+searchElement+'&practiceId='+JSON.parse(sessionStorage.getItem('practice')).Id+'&startDate='+startDate)
  }

  public getBillingReport(currentPage: any,pageSize: any, searchElement: string){
    // return this.syncGet('patient/insurer/99454/patient/billing_cycle')
    return this.syncGet('patient/insurer/99454/patient/billing_cycle?paginate='+pageSize+'&currentPage='+currentPage+'&searchElement='+searchElement+'&practiceId='+JSON.parse(sessionStorage.getItem('practice')).Id)
  }
}