import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConsentReportService } from '../consent-report/consent-report.service';

@Component({
  selector: 'app-dialogoverview',
  templateUrl: './dialogoverview.component.html',
  styleUrls: ['./dialogoverview.component.css']
})
export class DialogoverviewComponent {

  selectedDate: any;
  maxDate = new Date()

  constructor(public dialogRef: MatDialogRef<DialogoverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private consentService: ConsentReportService) { }

  ngOnInit() {
    console.log(this.data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(){
    console.log(this.selectedDate)
    if(this.selectedDate != undefined) this.dialogRef.close({ data: this.selectedDate })
  }

  getSelectedDate(event: any){
    console.log(event)
    this.selectedDate = event.value
  }


}
