import { Injectable } from "@angular/core";
import moment from "moment";
import { Subject } from "rxjs";
import { BaseService } from "src/app/base/base.service";

@Injectable({
  providedIn: 'root'
})

export class MasterListService extends BaseService {
  private formSubmittedSource = new Subject<string>();
  formSubmitted$ = this.formSubmittedSource.asObservable();

  submitForm(action: string) {
    this.formSubmittedSource.next(action);
  }

  public getMedicareReport(fromDate: any, toDate: any) {
    // `patient/report?startDate=${this.filterForm.value.from.toISOString().substring(0, 10)}&endDate=${this.filterForm.value.to.toISOString().substring(0, 10)}`
    // return this.syncGet('patient/report?startDate='+fromDate+'&endDate='+toDate)
    return this.syncGet(`patient/report/v1?startDate=${fromDate}&endDate=${toDate}&practiceId=${JSON.parse(sessionStorage.getItem('practice')).Id}`)
  }

  getLastUploadedDate() {
    // {{url}}/patient/update_upload_date
    return this.syncGet('patient/update_upload_date')
  }

  putClaimUpdate(claim: any) {
    return this.syncPut(`patient/update_claim`, claim)
  }

  getClaimData(claim: any) {
    return this.syncGet(`patient/view_claim?PatientId=${claim.PatientId}&Insurance=${encodeURIComponent(claim.Insurance)}&InsuranceDate=${moment(claim[`date_${claim['Insurance'].replace("#", "_")}`]).format("YYYY-MM-DD")}`)
  }
}