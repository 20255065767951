import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  public postLogin(credentials: any){
    return this.syncPost(`admin/login`, credentials)
  }

}
