import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/base/base.service';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({
    UserName: new FormControl('', Validators.required),
    Password: new FormControl('', Validators.required),
  })
  get userName() { return this.loginForm.get('UserName') }
  get password() { return this.loginForm.get('Password') }

  constructor(private _login: LoginService, private router: Router, private baseService: BaseService) { }

  ngOnInit() {
    sessionStorage.clear()
  }

  loginUser() {
    this._login.postLogin(this.loginForm.value)
      .subscribe(res => {
        console.log(res)
        if (res['status']) {
          sessionStorage.setItem('user', JSON.stringify(res['data']))
          sessionStorage.setItem('session', JSON.stringify(res['data']['SessionToken']))
          this.router.navigate(['/reports'])
        } else {
          this.baseService.showToast(res.errors[0], 'Close');
        }
      }, (error: any) => {
        console.log(error);
        if (error.error.errors) {
          this.baseService.showToast(error.error.errors[0], 'Close');
        } else {
          this.baseService.showToast(error.statusText, 'Close');
        }
      })
  }

}
