import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MasterListService } from '../masterlist/masterlist.service';

@Component({
  selector: 'app-claim-overview',
  templateUrl: './claim-overview.component.html',
  styleUrls: ['./claim-overview.component.css']
})
export class ClaimOverviewComponent implements OnInit {

  claimForm: FormGroup
  newClaim: boolean
  public get claimDate(): AbstractControl { return this.claimForm.get('ClaimedDate') }
  maxDate = new Date()

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private masterListService: MasterListService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.claimForm = this.data.form
    this.newClaim = this.data.newClaim
  }

  onSubmit() {
    console.log("onSubmit")
    this.claimForm.get('IsClaimed').setValue(1)
    this.masterListService.submitForm('form submitted')
  }

  clearClaim() {
    const message = `Are you sure you want to clear this claim?`;
    const dialogData = new ConfirmDialogModel("Clear Claim", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.claimForm.patchValue({
          "ClaimId": null,
          "ClaimedDate": null,
          "IsClaimed": null
        })
        this.masterListService.submitForm('form submitted')
      }
    });
  }

}
