import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MasterlistComponent } from '../masterlist/masterlist.component';
import { ConsentReportComponent } from '../consent-report/consent-report.component';
import { Report454Component } from '../report454/report454.component'
import { Report457Component } from '../report457/report457.component'
import { BillingcycleComponent } from '../billingcycle/billingcycle.component';

const routes: Routes = [
  // { path: '', pathMatch: "full", redirectTo: 'masterreport-claim' },
  { path: 'masterreport', component: MasterlistComponent },
  { path: 'masterreport-claim', component: MasterlistComponent },
  { path: 'consent-report', component: ConsentReportComponent },
  { path: 'report_454', component: Report454Component },
  { path: 'report_457', component: Report457Component },
  { path: 'billingreport_99454', component: BillingcycleComponent },
  // { path: '**', redirectTo: 'masterreport-claim', pathMatch: 'full' }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ReportRoutingModule { }
