import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { PracticePopupComponent } from '../practice-popup/practice-popup.component';
import { DashboardService } from './dashboard.service';
declare var $: any

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  // { name: 'Medicare Report', path: '/reports/masterreport', icon: 'assets/images/clipboard-list-solid.svg' },
  menu = [{ name: 'Medicare Report', path: '/reports/masterreport-claim', icon: 'assets/images/clipboard-list-solid.svg' }, { name: '99453', path: '/reports/consent-report', icon: 'assets/images/clipboard-list-solid.svg' }, { name: '99454', path: '/reports/report_454', icon: 'assets/images/clipboard-list-solid.svg' }, { name: '99457', path: '/reports/report_457', icon: 'assets/images/clipboard-list-solid.svg' }, { name: 'Patient Billing Cycle', path: '/reports/billingreport_99454', icon: 'assets/images/clipboard-list-solid.svg' },{ name: 'Logout', path: '/login', icon: 'assets/images/sign-out-alt-solid.svg' }]

  userName: string
  sidemenu: boolean = false;
  practiceList: any = []
  practiceName: string;
  
  constructor(private router: Router,public dialog: MatDialog, private dashboardService: DashboardService) { }

  ngOnInit() {
    console.log(sessionStorage.getItem('user'))
    this.userName = JSON.parse(sessionStorage.getItem('user')).Name
    console.log(this.userName)
    this.getAssignedPractices()
    // Side menu bar active
    $('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
    });
  }

  getAssignedPractices(){
    this.dashboardService.getPractices(100,1).subscribe(res => {
      if(res.status){
        this.practiceList = res.data
        console.log(this.practiceList)
        console.log(sessionStorage.getItem('practice'))
        if(sessionStorage.getItem('practice') == undefined){
          this.openDialog()
        } 
        else{
          this.practiceName = JSON.parse(sessionStorage.getItem('practice')).Name
          this.router.navigate(['/reports/masterreport-claim'])
        }
      }
    })
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PracticePopupComponent, {
      width: '520px',
      backdropClass: 'backdropBackground',
      disableClose: false,
      data: { practiceList: this.practiceList }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result)
      if(result.result){
        this.practiceName = result.data.Name
        console.log(this.practiceName)
        sessionStorage.setItem('practice', JSON.stringify(result.data))
        this.reloadCurrentRoute()
        // const currentUrl = this.router.url;
        // console.log(currentUrl)
        // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        //     this.router.navigate([currentUrl]);
        // });
        this.dialog.closeAll()
      }      
    });
  }

  logOut() {
    this.router.navigate(['/login'])
  }

}
