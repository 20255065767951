import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportRoutingModule } from './report-routing/report-routing.module';
import { MaterialModule } from '../material/material.module';
import { ConsentReportComponent } from './consent-report/consent-report.component';
import { MasterlistComponent } from './masterlist/masterlist.component';
// import { ExampleHeader, MasterlistComponent } from './masterlist/masterlist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapPipe } from '../shared/map.pipe';
import { DialogoverviewComponent } from './dialogoverview/dialogoverview.component';
import { UploadCountComponent } from './upload-count/upload-count.component';
import { Report454Component } from './report454/report454.component';
import { Report457Component } from './report457/report457.component';
import { BillingcycleComponent } from './billingcycle/billingcycle.component';
import { ClaimOverviewComponent } from './claim-overview/claim-overview.component';

@NgModule({
  declarations: [
    MasterlistComponent,
    ConsentReportComponent,
    MapPipe,
    DialogoverviewComponent,
    UploadCountComponent,
    Report454Component,
    Report457Component,
    BillingcycleComponent,
    ClaimOverviewComponent
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule
  ],
  entryComponents: [DialogoverviewComponent, ClaimOverviewComponent]
  // entryComponents: [DialogoverviewComponent, ExampleHeader]
})
export class ReportsModule { }
