import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { BaseService } from 'src/app/base/base.service';
import { DashboardService } from '../dashboard/dashboard.service';

@Component({
  selector: 'app-practice-popup',
  templateUrl: './practice-popup.component.html',
  styleUrls: ['./practice-popup.component.css']
})
export class PracticePopupComponent implements OnInit {
  @ViewChild('nameAutoCompleteTextbox', { static: true })
  textbox!: ElementRef<HTMLInputElement>;
  practiceList: any = []
  page: any = 1
  size: any = 10
  practiceName: string = ''

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PracticePopupComponent>,  private baseService: BaseService, private dashboardService: DashboardService
    ) {dialogRef.disableClose = true;}

  closeDialog(){
    this.dialogRef.disableClose = false
  }

  onCancelClick(): void {
    this.dialogRef.close({ data: '', result: false });
  }

  onOkClick(practice: any){
    this.dialogRef.close({ data: practice, result: true });
  }
  
  ngOnInit(): void {
    console.log(sessionStorage.getItem('practice'))
    console.log(this.data)
    // const list = this.data.practiceList.filter((item: any) => {
    //   return item.practiceName != null && item.practiceName != '';
    // });
    this.practiceList = this.data.practiceList.practice
    console.log(this.textbox)
    // const inputChanges$ = fromEvent(this.textbox.nativeElement, 'input');
    // inputChanges$
    //   .pipe(
    //     map((event) => (event.target as HTMLInputElement).value),
    //     debounceTime(500),
    //     distinctUntilChanged(),
    //     switchMap((x) => {
    //       console.log(this.practiceName)
    //       this.page = 1
    //       return this.dashboardService.getPractices(
    //         this.size,
    //         this.page,
    //         this.practiceName
    //       );
    //     })
    //   )
    //   .subscribe(this.getPracticeData,this.handleError);
  }

  selectPractice(practice: any){
    console.log(practice)
    sessionStorage.setItem('practice', JSON.stringify(practice))
  }

  // getPracticeData = (result: any) => {
  //   console.log(result);
  //   console.log(this.page)
  //   if (!result.hasError) {
  //     if (this.page === 1) this.practiceList = [];
      
  //     this.practiceList = [...this.practiceList, ...result['entityData']];
  //     if (result['entityData'].length){
  //       // this.page++
  //     }
  //     else
  //       this.baseService.showToast('No results found or the total record/s limit is reached.', 'Close');
  //   } else {
  //     console.log('errorrrrrrr');
  //     this.baseService.showToast(result.errors[0], 'Close');
  //   }
  // }

  // handleError = (error: any) => {
  //   console.log(error);
  //   if (error.error?.errors) {
  //     this.baseService.showToast(error.error.errors[0], 'Close');
  //   } else {
  //     this.baseService.showToast(error['statusText'], 'Close');
  //   }
  // }

  getPracticeList(page: any, lmt: any){
    // console.log(this.practiceName)
    // console.log(this.page)
    // console.log(page)
    // this.loginService.practiceList(this.page,lmt,this.practiceName).subscribe(this.getPracticeData, this.handleError);
  }

  practiceProfile(practice: any){
    console.log(practice)
    // this.dataService.setPracticeName(practice)
    // sessionStorage.setItem('practiceName', JSON.stringify(practice))
    // this.dialogRef.close()
    // this.router.navigate(['/home/practice/profile', practice.userId])
  }

  onScroll(e: any) {
    // visible height + pixel scrolled >= total height 
    if(e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight) {
      // console.log('load more data')
      // console.log(this.page)
      this.getPracticeList(this.page++, this.size);
    } else {
      // console.log('False')
    }
  }
}
