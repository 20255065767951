import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';


/*
This component acts as a messaging bus from service to ui components
*/

export enum CommandType {
    ShowLoader = 1,
    HideLoader = 2,
    ShowAlert = 3,
    HideAlert = 4,
    ShowError = 5
}

export const ShowLoader = {
    "CommandType" : CommandType.ShowLoader,
    "MessageText" : ""
}

export const ShowNetworkError = {
    "CommandType" : CommandType.ShowAlert,
	"MessageText" : "There was a problem with the request. Please check your internet connection and try again.",
	"Error": true
}

export const HideLoader = {
    "CommandType" : CommandType.HideLoader,
    "MessageText" : ""
}
export const HideAlert = {
    "CommandType" : CommandType.HideAlert,
    "MessageText" : ""
}


@Injectable({
    providedIn: 'root'
})
export class MessagingBusService {

    public Message = new BehaviorSubject<any>('')

    notify(data: any): any
    {
        return this.Message.next(data);
    }
}

